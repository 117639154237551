import React from 'react'
import { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import axios from "axios"
import TimePicker from 'react-time-picker';
import {Slider} from '@mui/material';
import ColorPickerModal from './colorpickermodal';

var alarm_button_opacity = 1;
var light_button_opacity = 0.25;

var degault_rgb_values = [
    ['(255, 130, 15)', '(255, 130, 15)'],
    ['(244, 213, 180)', '(244, 213, 180)'],
    ['(200, 0, 100)', '(100, 0, 90)'],
    ['(255, 120, 0)', '(255, 70, 0)'],
    ['(0, 0, 255)', '(100, 0, 90)'],
    ['(255, 0, 0)', '(0, 255, 0)', '(9, 0, 255)'],
    ['(0, 0, 255)', '(200, 0, 100)', '(255, 0, 0)', '(255, 50, 0)'],
    ['(213,119,235)', '(97,182,219)', '(218,177,119)']
];

export {degault_rgb_values};

const AlarmMenu = (props) => {

    let { id } = useParams(); 

    const [bgAnimate, setBgAnimate] = useState(false)

    const [showColorPickerModal, setShowColorPickerModal] = useState(false)

    const [mode, setMode] = useState('alarm')

    const [alarmEnabled, setAlarmEnabled] = useState(false)

    const [selectedIndex, setSelectedIndex] = useState(0)

    const [brightness, setBrightness] = useState(30)

    const [time, setTime] = useState('9:30')

    const [customIndexSelected, setCustomIndexSelected] = useState(false)

    const [customPreset, setCustomPreset] = useState(['(213,119,235)', '(97,182,219)', '(218,177,119)'])

    const [greeting, setGreeting] = useState('Welcome to Sunrise Alarm')


    useEffect(() => {
        fetch_current_data()
    },[]);

    useEffect(() => {
        console.log(time);
        if(selectedIndex >= degault_rgb_values.length){
            setCustomIndexSelected(true)
        }else{
            setCustomIndexSelected(false)
        }
    },[selectedIndex]); 

    const fetch_current_data = () => {
        axios.create({
            baseURL: 'https://sunrisealarm.app/api/alarm/' + id,
            headers: {
            "Accept" : "application/json",
            "Content-type": "application/json"
            }
        }).get().then(response => {
            var obj = JSON.parse(response.data[0].data)
            console.log(obj)
            setBrightness(obj.brightness)
            handleModeChange(obj.mode)
            setTime(obj.time)
            setAlarmEnabled(obj.alarm_enabled)
            setSelectedIndex(obj.color_profile)
            setCustomPreset(obj.custom_preset)
        })
        .catch(e => {
            setGreeting('Error fetching data for ' + id + '. Has user been created? /create');
            console.log(e);
            console.log(e.response);
        });
    }


    const update_data = async (e) => {
        console.log(e);

        triggerBgAnimation();
        axios.post('https://sunrisealarm.app/api/alarm/' + id, {
            name: id,
            data: JSON.stringify({
                mode: mode,
                alarm_enabled: alarmEnabled,
                brightness: brightness,
                time: time,
                color_profile: selectedIndex,
                color: "blue",
                default_presets: degault_rgb_values,
                custom_preset: customPreset
              })
          })
          .then(function (response) {
            // setGreeting('Changes saved!');
            resetGreetingDelayed();
            
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });

    }


    const getLinearGradient = (rgb_values_list) => {
        if(rgb_values_list.length <= 0){
            return 'linear-gradient(rgb(255,255,255), rgb(255,255,255)'
        }
        var css_string = 'linear-gradient(45deg,'
        for(const color of rgb_values_list){
            css_string += 'rgb' + color + ',';
        }
        css_string = css_string.slice(0, -1)
        css_string += ')'
        return css_string
        // return 'linear-gradient(45deg, rgb(213,119,235), rgb(97,182,219), rgb(218,177,119))'
    }

    const handleAlarmButton = (e) => {
        handleModeChange('alarm')
    }

    const handleLightButton = (e) => {
        handleModeChange('light')
    }

    const handleModeChange = (newMode) => {
        if(newMode === 'alarm'){
            alarm_button_opacity = 1;
            light_button_opacity = 0.25;
        }else{
            alarm_button_opacity = 0.25;
            light_button_opacity = 1;
        }
        setMode(newMode)
    }

    const resetGreetingDelayed = async () =>{
        await new Promise(r => setTimeout(r, 2000));
        setGreeting('Welcome to Sunrise Alarm')
    }

    const triggerBgAnimation = async () =>{
        setBgAnimate(true);
        await new Promise(r => setTimeout(r, 2000));
        setBgAnimate(false);
    }

    const handleAlarmToggle = (e) => {
        setAlarmEnabled(e.target.checked)
    }

    const handleColorPickerModalCancel = () => {
        setShowColorPickerModal(false)
    }

    const handleColorPickerModalShow = () => {
        setShowColorPickerModal(true)
    }

    const handleColorPickerSave = (color_list) => {
        console.log(color_list)
        setCustomPreset(color_list)
        setShowColorPickerModal(false)
    }

    // .....

    return(
        <>
        <ColorPickerModal
            showModal={showColorPickerModal}
            handleSubmit={handleColorPickerSave}
            handleCancel={handleColorPickerModalCancel}
            colors={customPreset}
            />
        <div className='fullscreen'>
            <div className={bgAnimate ? 'background background-animation' : 'background'} onAnimationEnd={() => {setBgAnimate(false)}}></div>
            <div className='main'>

                <h2>{greeting}</h2>
                <h2>User: {id}</h2>
                <div className='option'> 
                    <h3>
                        Mode:
                    </h3>
                    <button className='button ml1'
                        value={mode}
                        style={{opacity: alarm_button_opacity}}
                        onClick={e => handleAlarmButton(e)}>Alarm</button>
                    <button className='button ml1'
                        value={mode}
                        style={{opacity: light_button_opacity}}
                        onClick={e => handleLightButton(e)}>Light</button>
                </div>
                <div className='option'> 
                    <h3>
                        Alarm Time:
                    </h3>
                    {/* https://www.npmjs.com/package/react-time-picker */}
                    <TimePicker className='timepicker' onChange={setTime} value={time} />
                    <label class="switch">
                        <input type="checkbox" checked={alarmEnabled} onClick={e => handleAlarmToggle(e)}/>
                        <span class="slider"></span>
                    </label>
                </div>
                <div className='option'> 
                    <h3>
                        Brightness:
                    </h3>
                    
                    <Slider
                        value={brightness}
                        onChange={e => setBrightness(e.target.value)}
                        className='ml1'
                        color='secondary'
                        aria-label="Brightness"
                        valueLabelDisplay="auto"
                        step={1}
                        marks
                        min={0}
                        max={255}
                        />                
                </div>
                <div className='profile'> 
                    <h2>
                        Profile
                    </h2>
                    <div className='color_wrapper'>
                        {degault_rgb_values.map((rgb_value, index) => {
                            return <>
                            {selectedIndex === index ? (
                                <div key={index}
                                className='color_selection selected'
                                style={{background: getLinearGradient(rgb_value)}}
                                onClick={e => setSelectedIndex(index)}/>
                            ):(
                                <div key={index}
                                className='color_selection'
                                style={{background: getLinearGradient(rgb_value)}}
                                onClick={e => setSelectedIndex(index)}/>
                            )}
                            </>
                        })}
                        {customIndexSelected ? (
                            <div
                            className='color_selection selected color_picker_modal_parent'
                            style={{background: getLinearGradient(customPreset)}}
                            onClick={e => setSelectedIndex(degault_rgb_values.length)}>
                                <div
                                className='color_picker_modal_show'
                                onClick={e => handleColorPickerModalShow()}><h2>+</h2></div>
                            </div>
                            ):(
                            <div
                            className='color_selection color_picker_modal_parent'
                            style={{background: getLinearGradient(customPreset)}}
                            onClick={e => setSelectedIndex(degault_rgb_values.length)}>
                                <div
                                className='color_picker_modal_show'
                                onClick={e => handleColorPickerModalShow()}><h2>+</h2></div>
                            </div>
                        )}
                    </div>
                </div>
                <button className='button ml1 mt1'
                        onClick={(e) => update_data(e)}>Save</button>
            </div>
        </div>
        </>
    );
}
export default AlarmMenu
