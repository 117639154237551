import React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import axios from "axios";
import {Slider, Button, Backdrop} from '@mui/material';


const {degault_rgb_values} = require('./alarmMenu.js');

const UserCreate = (props) => {

    let { id } = useParams(); 

    const [name, setName] = useState('')

    const greeting = 'Welcome to Sunrise Alarm';

    useEffect(() => {
        console.log(name)
    },[name]);


    // const update_data = async () => {


    //     let data = {
    //         mode: "alarm",
    //         alarm_on: false,
    //         brightness: 15,
    //         time: "09:30 AM",
    //         color_profile: 1,
    //         color: "rainbow"
    //       };
    //     let payload = {
    //         name: "testuser",
    //         data: JSON.stringify(data)
    //       };
    //     let config = {
    //         url: 'https://sunrisealarm.app/test/alarm',
    //         method: 'POST',
    //         headers: {
    //             "Accept" : "application/json",		
	// 		    'Content-Type': 'application/json',
    //             'Access-Control-Allow-Origin': '*'
    //         },
    //         data: payload
    //     }
    //     axios.request(config=config).then(response => {
    //         console.log(response.data);
    //     })
    //     .catch(e => {
    //         console.log(e)
    //     });

    // }

    const update_data = async (username) => {
        console.log('saving user:' + name)
        axios.post('https://sunrisealarm.app/api/alarm', {
            name: username,
            data: JSON.stringify({
                mode: "alarm",
                alarm_on: false,
                brightness: 15,
                time: "09:30 AM",
                color_profile: 1,
                color: "rainbow",
                default_presets: degault_rgb_values,
                custom_preset: ['(213,119,235)', '(97,182,219)', '(218,177,119)']
              }),
            alarm: false
          })
          .then(function (response) {
            setName('Saved!')
            console.log(response);
          })
          .catch(function (error) {
            setName('error!')
            console.log(error);
          });

    }


    return(
        <div className='background'>
            <div className='main'>

                <h2>{greeting}</h2>
                <div className='option'> 
                    <h3>
                        Name:
                    </h3>

                    <input type="text" value={name} onChange={v => setName(v.target.value)}/>

                </div>
                <button className='button ml1 mt1'
                        onClick={() => update_data(name)}>Save</button>
            </div>
        </div>
    );
}
export default UserCreate
