import React from 'react'
import { useEffect, useState } from 'react'
import { HexColorPicker } from "react-colorful";

var rgb_list = ['(213,119,235)', '(97,182,219)', '(218,177,119)']
var list = ['(0,0,0)', '(0,0,0)']

const ColorPickerModal = (props) => {
    // Used for mood data

    const [color, setColor] = useState("#aabbcc");
    const [numTiles, setNumTiles] = useState(2)
    const [selectedIndex, setSelectedIndex] = useState(0)

    useEffect(() => {
        if(props.colors.length >= 2){
            list = props.colors
        }
    }, []);

    useEffect(() => {
        if(props.colors.length >= 2){
            list = props.colors
            setNumTiles(props.colors.length)
        }
    }, [props.colors]);

    useEffect(() => {
        console.log("test",  selectedIndex, numTiles)
    }, [selectedIndex]);

    useEffect(() => {
        if(selectedIndex >= numTiles){
            setSelectedIndex(numTiles - 1)
        }
    }, [numTiles]);

    useEffect(() => {
        var rgb_list = convertHEXtoRGB(color)
        list[selectedIndex] = "(" + String(rgb_list[0]) + "," + String(rgb_list[1]) + "," + String(rgb_list[2]) + ")"
    }, [color]);

    const handleAddTile = () => {
        list.push('(0,0,0)')
        setNumTiles(numTiles + 1)
        setSelectedIndex(numTiles)
    }

    const handleRemoveTile = (index) => {
        if(list.length <= 2){
            return
        }
        
        list.splice(index, 1)
        setNumTiles(list.length)

    }

    const handleCancel = () => {
        props.handleCancel()
    }

    const handleSubmit = () => {
        props.handleSubmit(list)
    }

    const getCSSColor = (rgb_value) => {
        if(rgb_value.includes('#')){
            var rgb_list = convertHEXtoRGB(rgb_value)
            return "rgb(" + String(rgb_list[0]) + "," + String(rgb_list[1]) + "," + String(rgb_list[2]) + ")" 
        }
        return "rgb" + String(rgb_value)
    }

    const convertHEXtoRGB = (hex_value) => {
        var hex = hex_value.replace(/^#/, '');
        if(hex.length !== 6){
            return [255,255,255];
        }
    
        var aRgbHex = hex.match(/.{1,2}/g);
        var aRgb = [
            parseInt(aRgbHex[0], 16),
            parseInt(aRgbHex[1], 16),
            parseInt(aRgbHex[2], 16)
        ];
        return aRgb;
    }

    const getLinearGradient = (rgb_values_list) => {
        if(rgb_values_list.length <= 0){
            return 'linear-gradient(rgb(255,255,255), rgb(255,255,255)'
        }
        var css_string = 'linear-gradient(45deg,'
        for(const color of rgb_values_list){
            css_string += 'rgb' + color + ',';
        }
        css_string = css_string.slice(0, -1)
        css_string += ')'
        return css_string
        // return 'linear-gradient(45deg, rgb(213,119,235), rgb(97,182,219), rgb(218,177,119))'
    }

    return (
        <>
        {props.showModal ? (
            <div className='modal_main'>
                <div className='modal_main_child'>
                    <div className='modal-header'
                        style={{background: getLinearGradient(list)}}>
                    </div>
                    <div className='modal-body'>
                        <div className='modal-color-wrapper'>
                            {list.map((rgb_value, index) => {
                                return <>
                                {selectedIndex === index ? (
                                    <div key={index}
                                    className='color_selection selected color_picker_modal_parent'
                                    style={{background: getCSSColor(rgb_value)}}
                                    onClick={e => setSelectedIndex(index)}>
                                        <div
                                        className='color_picker_modal_show'
                                        onClick={e => handleRemoveTile(index)}><h2>-</h2></div>
                                    </div>
                                ):(
                                    <div key={index}
                                    className='color_selection color_picker_modal_parent'
                                    style={{background: getCSSColor(rgb_value)}}
                                    onClick={e => setSelectedIndex(index)}>
                                        <div
                                        className='color_picker_modal_show'
                                        onClick={e => handleRemoveTile(index)}><h2>-</h2></div>
                                    </div>
                                )}
                                </>
                            })}
                            <div className='modal-tile-add'
                                onClick={e => handleAddTile()}><h1>+</h1></div>
                        </div>
                        <div className='modal-color-picker'>
                            <HexColorPicker color={color} onChange={setColor} />
                        </div>
                            
                    </div>
                    <div className='modal-footer'>
                        <div className='modal-footer-button' onClick={e => handleSubmit()}>
                            <div className='modal-footer-button-child'>
                                <h1>
                                    submit
                                </h1>
                            </div>
                        </div>
                        <div className='modal-footer-button' onClick={e => handleCancel()}>
                            <div className='modal-footer-button-child'>
                                <h1>
                                    cancel
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ):(
            <>
            </>
        )}
      </>
    )
}
export default ColorPickerModal